<template>
  <div>
    <label>Tipo de Notificação</label>
    <b-form-select
      :disabled="disabled"
      :options="selectOptions"
      :value="value"
      @change="change_value($event)"
    ></b-form-select>
  </div>
</template>

<script>
export default {
  props: ["value", "disabled"],
  computed: {
    globalState() {
      return this.$store.state.global;
    },
    selectOptions() {
      const first = {
        value: '',
        text: "Selecione o Tipo",
        disabled: true,
      };
      let notifications = _.map(
        this.globalState.notificationTypes,
        (notification) => {
          return { text: notification.description, value: notification.id };
        }
      );
      notifications.unshift(first);
      return notifications;
    },
  },
  methods: {
    change_value(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style>
</style>